import * as React from 'react';
import { Box, Heading, Stack, Image } from '@chakra-ui/react';
import RichText from "../../components/PrismicRichText";

const WhoWeAre = ({ whoWeAre }) => {
  return (
    <Stack
      p={{base:"5", sm:"0"}}
      maxW="81.625rem"
      justifyContent="space-between"
      spacing="5"
      ml="0"
      direction={{ base: "column", md: "row" }}>
      <Image
        mr="4rem"
        w={{base: "100%", sm: '634px'}}
        borderRadius={{base: '16px', sm:"0 16px 16px 0"}}
        objectFit="cover"
        height={{base: '200px', sm :"440px"}}
        src={whoWeAre.whoWeAreCover}
        />
      <Box minH="30.81rem" maxW="37.5rem">
        <Heading as="h3" fontSize="2xl">
          {whoWeAre.whoWeAreTitle}
        </Heading>
        <Stack spacing="4" mt="6">
          <Box fontSize="sm">
            <RichText text={whoWeAre.whoWeAreDescription}/>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
export default WhoWeAre;
