import * as React from 'react';
import {
  Box,
  Image,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Link } from 'gatsby-plugin-react-i18next';
import RichText from "../PrismicRichText";
const UserCard = ({ value, href }) => {
  return (
    <Box    
    _hover={{
      transform: "scale(1.05)",
      transition: "transform 0.3s ease" 
    }}>
 
      <Heading
        // mt="1.125rem"
        mb="1"
        as="h2"
        fontSize="lg">
          <Text as={Link} to={href}>
          <Image
        h="200px"
        w="210px"
        objectFit="cover"
        borderRadius="16px"
        src={value.image}
        mb="1.125rem"
      />
              {value.title}
          </Text>
      </Heading>
      <Box
        mb="3"
        fontSize="sm"
        opacity="0.7">
          <RichText text={value.description}/>
      </Box>
    </Box>
  )
};

export default UserCard;