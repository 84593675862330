import * as React from 'react'
import {
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react'
import UserCard from '../../components/usercard'

const BoardMemberCards = ({ trusteesBoards }) => {
  return (
    <SimpleGrid
      w="full"
      columns={{
        base: 1,
        sm: 2,
        lg: 3,
      }}
      spacing="3.125rem"
    >
      {trusteesBoards?.map((member, idx) => (
        <GridItem
          display="flex"
          justifyContent="center"
          position="relative"
          key={`member-${member.title}-${idx}`}>
          <UserCard value={member} href={`/trusteesborads-biography?id=${member.id}`} />
        </GridItem>
      ))}
    </SimpleGrid>
  );
};
export default BoardMemberCards;
