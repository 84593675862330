import * as React from 'react'
import { Container, Heading, Box } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import Seo from '../../components/SEO'
import Main from '../../components/layouts/Main'
import SmallNav from '../../components/smallNav'
import WhoWeAre from '../../components/aboutUs/WhoWeAre'
import AboutConstruction from '../../components/aboutUs/AboutConstruction'
import TrusteesBoard from '../../components/aboutUs/TrusteesBoard'
import BoardMembersCards from "../../components/aboutUs/BoardMembersCards"
import Mediators from "../../components/aboutUs/Mediators"
import Strategy from '../../components/aboutUs/Strategy';
import InterPartners from '../../components/aboutUs/InterPartners';
import BookletsAboutUs from '../../components/aboutUs/BookletsAboutUs';


import usePrismicAboutUs from "../../hooks/usePrismicAboutUs";
const isBrowser = () => typeof window !== "undefined"
const AboutUs = () => {
  const { language } = useTranslation()

  const section = isBrowser() && window.location.hash.replace('#', '');
  const scrollToRef = (offsetTop) => window.scrollTo(0, offsetTop)
  const offsetTop = (ref) => ref.current.offsetTop - 150
  const structureRef = React.useRef(null)
  const trusteesRef = React.useRef(null)
  const mediatorRef = React.useRef(null)
  const strategyRef = React.useRef(null)
  const aboutUsData = usePrismicAboutUs();
  React.useEffect(() => {

    if (section === 'structure') {
      scrollToRef(offsetTop(structureRef))
    }
    if (section === 'board-of-trustees') {
      scrollToRef(offsetTop(trusteesRef))
    }
    if (section === 'mediators') {
      scrollToRef(offsetTop(mediatorRef))
    }
    if (section === 'strategy') {
      scrollToRef(offsetTop(strategyRef))
    }
  }, [section])
  return(
    <Main>
      <Box overflow="hidden">

        <Container
          px="5"
          maxW="7xl">
          <Seo
            title={aboutUsData.navText}
            description={aboutUsData.navText}
            link={`https://www.fsm.am/${language}/about-us`}
          />
          <SmallNav>
            {aboutUsData.navText}
          </SmallNav>
          <Heading
            as="h1"
            fontSize={{base:'2xl', sm: "3xl"}}
            fontWeight="900"
            mt={{base: '3', sm: "5"}}
            mb={{base: '1.875rem', sm: "10"}}>
            {aboutUsData.title}
          </Heading>
        </Container>
        <Container
          px="5"
          maxW="7xl">
          <WhoWeAre whoWeAre={aboutUsData} />
          <AboutConstruction aboutConstruction={aboutUsData} ref={structureRef}/>
          <TrusteesBoard trusteesBoard={aboutUsData} ref={trusteesRef}/>
          </Container>
        <Container
          px="5"
          overflow="visible"
          maxW="7xl">
          <BoardMembersCards trusteesBoards={aboutUsData.trusteesborads} />
          <Mediators data={aboutUsData} mediators={aboutUsData.mediators} ref={mediatorRef}/>
          {/* <InterPartners inter={aboutUsData}/>
          <BookletsAboutUs booklets={aboutUsData}/> */}
          <Strategy strategy={aboutUsData} ref={strategyRef}/>
        </Container>
      </Box>
    </Main>
  )
}

export default AboutUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`