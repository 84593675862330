import React from "react";
import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import EventsCard from "../newsReports/EventsCard";
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";
import { DEFAULT_IMAGE } from "../../static/constants";
import { Link } from "gatsby-plugin-react-i18next";
import ProductCardforEvents from "../productcard/ProductCardforEvents";

export default ({
  slides,
  perView,
  spaceBetween = 28,
  overflow,
  href,
  buttonText,
  from,
  date = true,
  ...rest
}) => {
  return (
    <Swiper
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={spaceBetween}
      breakpoints={{
        380: {
          slidesPerView: 1,
        },
        560: {
          slidesPerView: 2,
        },
        760: {
          slidesPerView: perView,
        },
      }}
      navigation={{
        nextEl: ".right",
        prevEl: ".left",
      }}
      scrollbar={{ draggable: true }}
      style={{
        overflow: overflow,
        width: "100%",
      }}
    >
      {slides?.map((slide, idx) => (
        <SwiperSlide
          key={idx}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {from === "news-reports" ? (
            <EventsCard
              title={slide.title}
              image={{
                file: {
                  url: slide.image ? slide.image : DEFAULT_IMAGE,
                },
              }}
              description={slide.description}
              cardDate={slide.date}
              date={date}
              idNews={slide.id && slide.id}
              mainButton
              {...rest}
            />
          ) : (
            <ProductCardforEvents
              title={slide.title}
              image={{
                file: {
                  url: slide.thumbnail || slide.image || DEFAULT_IMAGE,
                },
              }}
              description={slide.description}
              idNews={slide.id && slide.id}
              date={date}
              cardDate={slide.date && slide.date}
              mainButton
              hrefNews={href}
              {...rest}
            />
          )}
        </SwiperSlide>
      ))}
      {from === "news-reports" && (
        <Flex
          justifyContent={{
            base: "flex-start",
            sm: "center",
          }}
          mt={10}
        >
          <Link to="/events">
            <Button variant="transparent" w="172px">
              {buttonText}
            </Button>
          </Link>
        </Flex>
      )}
      <Stack spacing="3rem" mt="2rem" direction="row">
        {from === "financial-education" && (
          <Button
            variant="transparent"
            w="173px"
            as={Link}
            to={`/all-financial-education-events`}
          >
            {buttonText}
          </Button>
        )}
        <Box className="left" as={Button}>
          <LeftButton />
        </Box>
        <Box className="right" as={Button}>
          <RightButton />
        </Box>
      </Stack>
    </Swiper>
  );
};
