import React from 'react'
import {
  Flex,
  Button,
  Box,
} from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Scrollbar, A11y, Navigation } from 'swiper'
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";
import 'swiper/css/navigation'
import 'swiper/css'
import ProductCard from '../../components/productcard'

export default (props) => {
  const {slides, perView, spaceBetween = 28, overflow, links, ...rest} = props
  return (
    <Swiper
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={spaceBetween}
      navigation={{
        nextEl: '.right',
        prevEl: '.left',
      }}
      slidesPerView={perView}
      scrollbar={{ draggable: true }}
      style={{overflow: overflow}}
    >
      {
        links.map((link, idx) => (
          <SwiperSlide key={`link-${link.title}-${idx}`} style={{width: '100%', height: '100%'}} >
            <ProductCard
              src={link.image}
              {...rest}
              title={link.title}
              href={link.url}
              description={link.description}
            />
          </SwiperSlide>
        ))
      }
      <Flex mt="2rem">
        <Box className="left" as={Button}>
          <LeftButton />
        </Box>
        <Box className="right" as={Button}>
          <RightButton />
        </Box>
      </Flex>
    </Swiper>
  );
};