import * as React from "react";
import { Box, Heading } from "@chakra-ui/react";
import CardSwiper from "./CoreValuesSlide"
import RichText from "../PrismicRichText";
const w = typeof window !== 'undefined' && window.innerWidth;

const Strategy = React.forwardRef(({ strategy }, ref) => {
  return (
    <Box ref={ref} mb="5.5rem" overflow="visible" w="full">
      <Box mt="14" mb="1.875rem">
        <Heading fontSize="2xl" as="h2" mb="6">
            {strategy.strategyTitle}
        </Heading>
        <Box fontSize="sm">
            <RichText text={strategy.strategyDescription}/>
        </Box>
      </Box>
      <CardSwiper
        overflow="visible"
        product
        perView={w < 481 ? 1.2 : 3}
        borderRadius="24px"
        links={strategy.strategyLinks}
      />
    </Box>
  );
})

export default Strategy;
