import * as React from "react";
import { Box, Heading, Flex, Stack, } from "@chakra-ui/react";
import UserCard from "../../components/usercard";
import RichText from "../PrismicRichText";
const Mediators = React.forwardRef(({ data, mediators },ref) => {
  return (
    <Box ref={ref} mb="5.5rem" w="full">
      <Flex flexDirection="column" alignItems={{base: 'flex-start', sm: "center"}} mt="9.625rem">
        <Heading textAlign={{base: 'start', sm: "center"}} fontSize="2xl" as="h2" mb="6">
            {data.mediatorTitle}
        </Heading>
        <Box textAlign={{base: 'start', sm: "center"}} fontSize="sm">
            <RichText text={data.mediatorsDescription}/>
        </Box>
        <Stack
          maxW="600px"
          w="full"
          justifyContent="space-between"
          alignItems="center"
          mt="4.5rem"
          direction={{ base: "column", sm: "row" }}
          spacing="6"
        >
          {mediators.map((member, idx) => (
            <UserCard
              key={`member-${member.title}-${idx}`}
              value={member}
              href={`/mediators-biography?id=${member.id}`}
            />
          ))}
        </Stack>
      </Flex>
    </Box>
  );
})

export default Mediators;
