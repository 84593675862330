import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import {
  Box,
  Heading,
  Container,
  Image,
  Button,
  Stack,
} from "@chakra-ui/react";
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Navigation } from "swiper";
import CardSwiper from "../../components/cardSwiper";
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";

const w = typeof window !== "undefined" && window.innerWidth;

const BookletsAboutUs = React.forwardRef(({ booklets }, ref) => {
  return (
    <Box ref={ref} overflow="hidden">
      <Container px="5" maxW="7xl">
        <Heading fontSize="2xl" mb="3.75rem">
          <Trans>{booklets.titleBookletsAboutUs}</Trans>
        </Heading>
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={28}
          navigation={{
            nextEl: ".right",
            prevEl: ".left",
          }}
          slidesPerView={w < 481 ? 1.2 : 3}
          scrollbar={{ draggable: true }}
        >
          {booklets.bookletsAboutUs?.map((item, idx) => (
            <SwiperSlide
              key={`slide-${item.link}-${idx}`}
              style={{ width: "100%", height: "428px" }}
            >
              <Link
                href={item.link}
                target="_blank"
                _focus={{ boxShadow: "none" }}
              >
                <Image
                  h="90%"
                  w="90%"
                  objectFit="cover"
                  borderRadius="24px"
                  src={item.imageaboutusbooklets}
                />
              </Link>
            </SwiperSlide>
          ))}
          <Stack spacing="3rem" mt="2rem" direction="row">
            <Button
              variant="transparent"
              w="173px"
              as={Link}
              to={`/all-information-booklets-aboutus`}
            >
              {booklets.bookletsAboutUsShowButton}
            </Button>
            <Box className="left" as={Button}>
              <LeftButton />
            </Box>
            <Box className="right" as={Button}>
              <RightButton />
            </Box>
          </Stack>
        </Swiper>
      </Container>
    </Box>
  );
});
export default BookletsAboutUs;
