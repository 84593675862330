import React from "react";
import {
    Box,
    Stack,
    Button,
    Collapse,
    Heading as ChakraHeading,
    useDisclosure
} from "@chakra-ui/react";
import RichText from "../PrismicRichText";
import ConvertDate from "../ConvertDate";

const EventsCard = ({
     shadow = '0 0 24px 4px rgba(0,0,0,0.15)',
     title,
     mainButton,
     date,
     cardDate,
     description,
     borderRadius,
     mb,
     ...rest
 }) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });

    return (
        <Box
            flex="1"
            mb={mb}
            overflow="hidden"
            boxShadow={shadow}
            borderRadius={borderRadius && "24px"}
            minH="250"
            _hover={{
                transform: "scale(1.03)",
                transition: "0.1s",
                boxShadow: "4px 4px 4px 4px rgba(63, 167, 60)",
              }}>
            <Stack spacing={2} p="4" pt="2">
                {date && (
                    <ConvertDate date={cardDate} />
                )}
                {!!title && (
                    <ChakraHeading
                    fontFamily="Mardoto"
                    minH="68px"
                    noOfLines={3}
                    lineHeight="1.33"
                    fontSize="lg">
                        {title}
                    </ChakraHeading>
                )}
                {
                    !!description && description.length > 0 && description[0].text !== '' && (
                        <Box
                            fontFamily="Mardoto"
                            minH="80px"
                            noOfLines={4}
                            fontSize="sm"
                            color="grey.100">
                            <Collapse startingHeight={60} in={isOpen} >
                                <RichText text={description}/>
                            </Collapse>
                        </Box>
                    )
                }
            </Stack>
            {!!mainButton && (
                <Stack spacing={2} p="4" pt="2">
                    <Button
                        onClick={onToggle}
                        borderRadius="0 0 24px 24px"
                        w="full"
                        color="green.100"
                        display="block"
                        textAlign="left"
                        pb={4}
                    >
                        {!isOpen ? 'Տեսնել ավելին' : 'Փակել'}
                    </Button>
                </Stack>
            )}
        </Box>
    );
};

export default EventsCard;
