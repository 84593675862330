import * as React from 'react';
import { Box, Heading, Stack, Image} from '@chakra-ui/react';
import "../../css/typography.css"
import RichText from "../PrismicRichText";
const AboutConstuction = React.forwardRef(({ aboutConstruction }, ref) => {
  return (
    <Stack
      ref={ref}
      maxW="73.25rem"
      justifyContent="space-between"
      alignItems="center"
      spacing={{base: '3.7rem', sm: "5" }}
      mb={{base:"15",md:"4rem"}}
      py= {{base:"0", md: "8.25rem"}}
      direction={{ base: "column", md: "row" }}
     
    >
      <Box 
        px={{base:"5"}}
        maxW="23.25rem"
        >
        <Heading as="h2" fontSize="2xl">
          {aboutConstruction.aboutConstuctionTitle}
        </Heading>

        <Box fontSize="sm" mt="4" mb="8">
          <RichText text={aboutConstruction.aboutConstuctionDescription} />
        </Box>
      </Box>
      <Box
        px="5"
        w="100%"
        overflow="hidden"
        >
        <Image
          src={aboutConstruction.image}
          borderColor="border.100"
          w="full"
          h="436px"
          minW="672px"
          />
      </Box>
    </Stack>
  );
})

export default AboutConstuction;
