import * as React from "react";
import { Box, Heading, Stack, Image } from "@chakra-ui/react";
import RichText from "../PrismicRichText";
const TrusteesBoard = React.forwardRef(({ trusteesBoard }, ref) => {
  return (
    <Stack
      ref={ref}
      spacing="100px"
      ml="0"
      mb="6.25rem"
      direction={{ base: "column", md: "row" }}
    >
      <Image
        objectFit="cover"
        borderRadius="0 16px 16px 0"
        display={{base: 'none', md: 'block'}}
        minH="27.5rem"
        maxW="434px"
        src={trusteesBoard.trusteesBoardCover}
      />
      <Box maxH="full" maxW="772px" px="5" py="6">
        <Heading as="h3" fontSize="2xl" mb="6">
          {trusteesBoard.trusteesBoardTitle}
        </Heading>
        <Box display="inline-block" fontSize="sm">
          <RichText text={trusteesBoard.trusteesBoardDescription}/>
        </Box>
      </Box>
    </Stack>
  );
})

export default TrusteesBoard;
