import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

const usePrismicAboutUs = () => {
  const { language } = useI18next();
  const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am';

  const response = useStaticQuery(graphql`
    query PrismicAboutUs {
      allPrismicAboutus(filter: {tags: {in: "about_us"}}) {
        nodes {
          lang
          data {
            navtext
            mediators {
              id
              title1 {
                text
              }
              image1 {
                url
              }
              description {
                richText
              }
              biography {
                richText
              }
            }
            trusteesborads {
              id
              title1 {
                text
              }
              description {
                richText
              }
              biography {
                richText
              }
              image1 {
                url
              }
            }
            links {
              title1 {
                text
              }
              description {
                richText
              }
              image1 {
                url
              }
              link {
                url
              }
              date
            }
            title {
              text
            }
            whowearetitle {
              text
            }
            whowearecover {
              url
            }
            whowearedescription {
              richText
            }
            interpartnerstitle {
              text
            }
            interpartnerscover {
              url
            }
            interpartnersdescription {
              richText
            }
            titlebookletsaboutus {
              text
            }
            bookletsaboutus {
              imageaboutusbooklets {
                url
              }
              linkaboutusbooklets {
                url
              }
            }
            bookletsaboutusshowbutton
            aboutconstuctiontitle {
              text
            }
            aboutconstuctiondescription {
              richText
            }
            aboutconstuctionbuttontext
            image {
              url
            }
            trusteesboardtitle {
              text
            }
            trusteesboarddescripton {
              richText
            }
            trusteesboardcover {
              url
            }
            mediatortitle {
              text
            }
            mediatorsdescription {
              richText
            }
            strategytitle {
              text
            }
            strategydescription {
              richText
            }
          }
        }
      }
    }
  `);

  // Логируем весь ответ от useStaticQuery
  // console.log("Prismic About Us response:", response);

  const filteredData = response.allPrismicAboutus.nodes.filter(item => item.lang === newLang);
  
  // Логируем отфильтрованные данные
  // console.log("Filtered data:", filteredData);

  if (filteredData.length === 0) {
    // console.warn("No data found for the current language:", newLang);
    return null;
  }

  return sanitize(filteredData[0].data);
};

const sanitize = (response) => {
  return {
    navText: response.navtext,
    interPartnersDescription: response.interpartnersdescription.richText,
    interPartnersTitle: response.interpartnerstitle.text,
    interPartnersCover: response.interpartnerscover.url,
    titleBookletsAboutUs: response.titlebookletsaboutus.text,
    bookletsAboutUs: response.bookletsaboutus.map(booklet => ({
      linkaboutusbooklets: booklet.linkaboutusbooklets.url,
      imageaboutusbooklets: booklet.imageaboutusbooklets.url,
    })),
    bookletsAboutUsShowButton: response.bookletsaboutusshowbutton,
    aboutConstuctionButtonText: response.aboutconstuctionbuttontext,
    aboutConstuctionDescription: response.aboutconstuctiondescription.richText,
    aboutConstuctionTitle: response.aboutconstuctiontitle.text,
    mediatorsDescription: response.mediatorsdescription.richText,
    mediatorTitle: response.mediatortitle.text,
    strategyDescription: response.strategydescription.richText,
    strategyTitle: response.strategytitle.text,
    trusteesBoardDescription: response.trusteesboarddescripton.richText,
    trusteesBoardTitle: response.trusteesboardtitle.text,
    whoWeAreDescription: response.whowearedescription.richText,
    whoWeAreTitle: response.whowearetitle.text,
    trusteesBoardCover: response.trusteesboardcover.url,
    whoWeAreCover: response.whowearecover.url,
    title: response.title.text,
    image: response.image.url,
    mediators: response.mediators.map(mediator => ({
      id: mediator.id,
      title: mediator.title1.text,
      description: mediator.description.richText,
      biography: mediator.biography.richText,
      image: mediator.image1.url,
    })),
    trusteesborads: response.trusteesborads.map(trusteesborad => ({
      id: trusteesborad.id,
      title: trusteesborad.title1.text,
      description: trusteesborad.description.richText,
      biography: trusteesborad.biography.richText,
      image: trusteesborad.image1.url,
    })),
    strategyLinks: response.links.map(link => ({
      title: link.title1.text,
      description: link.description.richText,
      image: link.image1.url,
      date: link.date,
      url: link.link.url,
    })),
  };
};

export default usePrismicAboutUs;


//previus version with trusteesboards-biography page
// import { graphql, useStaticQuery } from 'gatsby';
// import { useI18next } from 'gatsby-plugin-react-i18next'

// const usePrismicAboutUs = () => {
//     const { language } = useI18next()
//     const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

//     const response = useStaticQuery(graphql`
//     query PrismicAboutUs {
//      allPrismicAboutus(filter: {tags: {in: "about_us"}}) {
//             nodes {
//               lang
//               data {
//                 navtext
//                 mediators {
//                   id
//                   title1 {
//                     text
                
//                   }
//                   image1 {
//                     url
//                   }
//                   description {
//                     richText
                
//                   }
//                   biography {
//                     richText
                
//                   }
//                 }
//                 trusteesborads {
//                   id
//                   title1 {
//                     text
//                   }
//                   description {
//                     richText
//                   }
//                   biography {
//                     richText
//                   }
//                   image1 {
//                     url
//                   }
//                 }
//                 links {
//                   title1 {
//                     text
                
//                   }
//                   description {
//                     richText
                
//                   }
//                   image1 {
//                     url
//                   }
//                   link {
//                     url
//                   }
//                   date
//                 }
//                 title {
//                   text
                
//                 }
//                 whowearetitle {
//                   text
                
//                 }
//                 whowearecover {
//                   url
//                 }
//                 whowearedescription {
//                   richText
                
//                 }

//                 interpartnerstitle {
//                   text
                
//                 }
//                 interpartnerscover {
//                   url
//                 }
//                 interpartnersdescription {
//                   richText
                
//                 }
 
                
//                 titlebookletsaboutus {
//                   text
//                 }

//                 bookletsaboutus {
//                   imageaboutusbooklets {
//                     url
//                   }
//                   linkaboutusbooklets {
//                     url
//                   }
//                 }
//                 bookletsaboutusshowbutton

//                 aboutconstuctiontitle {
//                   text
                
//                 }
//                 aboutconstuctiondescription {
//                   richText
//                 }
//                 aboutconstuctionbuttontext
//                 image {
//                   url
//                 }
//                 trusteesboardtitle {
//                   text
                
//                 }
//                 trusteesboarddescripton {
//                   richText
                
//                 }
//                 trusteesboardcover {
//                   url
//                 }
//                 mediatortitle {
//                   text
                
//                 }
//                 mediatorsdescription {
//                   richText
                
//                 }
//                 strategytitle {
//                   text
                
//                 }
//                 strategydescription {
//                   richText
                
//                 }
//               }
//             }
//           }
//       }
//   `);
//     return sanitize(response.allPrismicAboutus.nodes.filter(item => item.lang === newLang)[0].data)
// };

// const sanitize = (response) => {
//     return {
//         navText: response.navtext,

//         // contactUsTitle: response.contactustitle.text,
//         // contactUsCover: response.contactuscover.url,
//         // contactUsLink: response.contactuslink,

//         // fillComplaintTitle: response.fillcomplainttitle.text,
//         // fillComplaintCover: response.fillcomplaintcover.url,
//         // fillComplaintLink: response.fillcomplaintlink,

//         // applyMediatorTitle: response.applymediatortitle.text,
//         // applyMediatorCover: response.applymediatorcover.url,
//         // applyMediatorLink: response.applymediatorlink,

//         // orderCallTitle: response.ordercalltitle.text,
//         // orderCallCover: response.ordercallcover.url,
//         // orderCallLink: response.ordercalllink,







//         interPartnersDescription: response.interpartnersdescription.richText,
//         interPartnersTitle: response.interpartnerstitle.text,
//         interPartnersCover: response.interpartnerscover.url,

        
//         titleBookletsAboutUs: response.titlebookletsaboutus.text,

//         bookletsAboutUs://bookletsAboutUs
//         response.bookletsaboutus.map(//bookletsaboutus
//           (bookletsAboutUs) => ({//bookletsAboutUs
//             linkaboutusbooklets: bookletsAboutUs.linkaboutusbooklets//bookletsAboutUs
//               .url,
//               imageaboutusbooklets:
//             bookletsAboutUs.imageaboutusbooklets//bookletsAboutUs
//                 .url,
//           })
//         ),
//         bookletsAboutUsShowButton: response.bookletsaboutusshowbutton,

        



//         aboutConstuctionButtonText: response.aboutconstuctionbuttontext,
//         aboutConstuctionDescription: response.aboutconstuctiondescription.richText,
//         aboutConstuctionTitle: response.aboutconstuctiontitle.text,
//         mediatorsDescription: response.mediatorsdescription.richText,
//         mediatorTitle: response.mediatortitle.text,
//         strategyDescription: response.strategydescription.richText,
//         strategyTitle: response.strategytitle.text,
//         trusteesBoardDescription: response.trusteesboarddescripton.richText,
//         trusteesBoardTitle: response.trusteesboardtitle.text,
//         whoWeAreDescription: response.whowearedescription.richText,
//         whoWeAreTitle: response.whowearetitle.text,
//         trusteesBoardCover: response.trusteesboardcover.url,
//         whoWeAreCover: response.whowearecover.url,
//         title: response.title.text,
//         image: response.image.url,
//         mediators: response.mediators.map(mediator => ({
//             id: mediator.id,
//             title: mediator.title1.text,
//             description: mediator.description.richText,
//             biography: mediator.biography.richText,
//             image: mediator.image1.url,
//         })),
//         trusteesborads: response.trusteesborads.map(trusteesborad => ({
//             id: trusteesborad.id,
//             title: trusteesborad.title1.text,
//             description: trusteesborad.description.richText,
//             biography: trusteesborad.biography.richText,
//             image: trusteesborad.image1.url,
//         })),
//         strategyLinks: response.links.map(link => ({
//             title: link.title1.text,
//             description: link.description.richText,
//             image: link.image1.url,
//             date: link.date,
//             url: link.link.url
//         }))
//     }
// }
// export default usePrismicAboutUs;
