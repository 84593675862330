import React from "react";
import { Box, Flex, Stack, Image, Button } from "@chakra-ui/react";
import { Link } from "gatsby-plugin-react-i18next";
import { getUrlFromOldToNewBucket } from "../../helpers";
import Heading from "./Heading";
import RichText from "../PrismicRichText";
import ConvertDate from "../ConvertDate";

const ProductCardforEvents = ({
  shadow = "0 0 24px 4px rgba(0,0,0,0.15)",
  title,
  mainButton,
  mainButtonLink,
  date,
  cardDate,
  description,
  cardNumber,
  product,
  src,
  borderRadius,
  moreButton,
  buttonText,
  empty,
  image,
  minW,
  mb,
  id,
  urls,
  href,
  idNews = null,
  hrefNews = null,
  ...rest
}) => {
 

  return (
    <Box
      minW={minW}
      flex="1"
      mb={mb}
      overflow="hidden"
      boxShadow={shadow}
      borderRadius={borderRadius && "24px"}
      _hover={{
        transform: "scale(1.05)",
        transition: "0.1s",
        boxShadow: "4px 4px 4px 4px rgba(63, 167, 60)",
      }}
    >
      <Flex position="relative">
        {product && (
          <>
            <Image
              height={200}
              width="full"
              objectFit="cover"
              style={{
                borderRadius,
              }}
              alt="About us"
              src={
                src
                  ? getUrlFromOldToNewBucket(src)
                  : getUrlFromOldToNewBucket(image?.file.url)
              }
            />
          </>
        )}
      </Flex>
      <Stack spacing={2} p="4" pt="2">
        <Box p="1" h="26px">
          {date && cardDate && <ConvertDate date={cardDate} />}
        </Box>
        {!!title && (
          <Heading title={title} href={href} hrefNews={hrefNews} idNews={idNews} />
        )}
        {!!description &&
          description.length > 0 &&
          description[0].text !== "" && (
            <Box
              fontFamily="Mardoto"
              minH="80px"
              noOfLines={4}
              fontSize="sm"
              color="grey.100"
            >
              <RichText text={description} />
            </Box>
          )}
      </Stack>
      {moreButton && (
        <Button
          as={id && Link}
          to={`/${urls}?id=${id}`}
          target="_blank"
          variant="text"
          color="green.100"
          p="1rem"
        >
          {buttonText}
        </Button>
      )}
      {!!mainButton && !!mainButtonLink && (
        <Stack spacing={2} p="4" pt="2">
          <Button
            as={Link}
            to={mainButtonLink}
            borderRadius="0 0 24px 24px"
            w="full"
            color="green.100"
            display="block"
            textAlign="left"
            pb={4}
          >
            {mainButton}
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default ProductCardforEvents;
